exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-chessboxing-js": () => import("./../../../src/pages/chessboxing.js" /* webpackChunkName: "component---src-pages-chessboxing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */)
}

